import { Fragment } from 'react';
import { Disclosure } from '@headlessui/react';
import MobileNavbar from "./MobileNavbar";
import DesktopNavBar from "./DesktopNavbar";
import {useLocation} from 'react-router-dom';


export default function Navbar(props) {
    const location = useLocation();
    const currentPage = location.pathname;

    const desktopNavigation = [
        { id: 'dn1', name: 'Home', href: '/', current: '/' == currentPage ? true : false, target: "", rel: "" },
        { id: 'dn2', name: 'API', href: '/api', current: '/api' == currentPage ? true : false, target: "", rel: "" },
    ];

    const mobileNavigation = [
        { id: 'mn1', name: 'Home', href: '/', current: '/' == currentPage ? true : false, target: "", rel: "" },
        { id: 'mn2', name: 'API', href: '/', current: '/' == currentPage ? true : false, target: "", rel: "" },
    ];

    const articlesNav = [
        { id: 'an1', name: 'Why Boycott Israel?', href: '/why-should-you-boycott', current: '/why-should-you-boycott' == currentPage ? true : false, target: "", rel: "" },
        { id: 'an2', name: 'Understanding the Turmoil', href: '/learn-why-israel-will-continue-in-turmoil', current: '/learn-why-israel-will-continue-in-turmoil' == currentPage ? true : false, target: "", rel: "" }
    ];

    const categoriesNav = [
        { id: 'cn0', name: 'All Companies', href: '/companies', current: '/companies' == currentPage ? true : false, target: "", rel: "" },
        { id: 'cn1', name: 'Technology', href: '/companies/technology', current: '/companies/technology' == currentPage ? true : false, target: "", rel: "" },
        { id: 'cn2', name: 'Food & Beverage', href: '/companies/food-and-beverage', current: '/companies/food-and-beverage' == currentPage ? true : false, target: "", rel: "" },
        { id: 'cn4', name: 'Health', href: '/companies/health', current: '/companies/health' == currentPage ? true : false, target: "", rel: "" },
        { id: 'cn5', name: 'Finance', href: '/companies/finance', current: '/companies/finance' == currentPage ? true : false, target: "", rel: "" },
        { id: 'cn6', name: 'Entertainment', href: '/companies/entertainment', current: '/companies/entertainment' == currentPage ? true : false, target: "", rel: "" },
        { id: 'cn7', name: 'Transportation', href: '/companies/transportation', current: '/companies/transportation' == currentPage ? true : false, target: "", rel: "" },
        { id: 'cn8', name: 'Productivity', href: '/companies/productivity', current: '/companies/productivity' == currentPage ? true : false, target: "", rel: "" },
        { id: 'cn9', name: 'Marketing', href: '/companies/marketing', current: '/companies/marketing' == currentPage ? true : false, target: "", rel: "" },
        { id: 'cn10', name: 'Manufacturing', href: '/companies/manufacturing', current: '/companies/manufacturing' == currentPage ? true : false, target: "", rel: "" },
        { id: 'cn12', name: 'Energy', href: '/companies/energy', current: '/companies/energy' == currentPage ? true : false, target: "", rel: "" }
    ];


    return (
        <>
            <div className="min-h-full max-w mx-auto">
                <Disclosure as="nav" className="bg-white">
                    {({ open }) => (
                        <>
                            <DesktopNavBar
                                key="desktop-navbar"
                                navigation={desktopNavigation}
                                articles={articlesNav}
                                categories={categoriesNav}
                                open={open} />
                            <MobileNavbar
                                key="mobile-navbar"
                                navigation={mobileNavigation}
                                articles={articlesNav}
                                categories={categoriesNav}
                            />
                        </>
                    )}
                </Disclosure>
            </div>
        </>
    )
};