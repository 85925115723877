import {Fragment} from 'react'
import Navbar from "../organisms/Navbar";
import Footer from "../organisms/Footer";
import {PageTitle} from "../atoms/PageTitle";
import {ApiSpec} from "../organisms/ApiSpec";

export default function Api() {

    return (
        <>
            <PageTitle title="API - Boycott Israeli Businesses"/>
            <Navbar key="navbar"/>
            <header className="bg-gray-100 h-full">
                <div className="mx-auto py-4 px-7 h-full">
                    <ApiSpec />
                </div>
            </header>
            <main className="bg-gray-100 h-full"></main>
            <Footer/>
        </>
    );
};